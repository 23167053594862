import Div100vh from 'react-div-100vh'

import { Locale } from '@top/shared_deprecated/src/types/Languages'
import {
  IScene,
  SceneToTemplate,
  SceneType,
  TemplateType,
} from '@top/shared_deprecated/src/types/Scene'
import { lazyRetry } from '@top/shared_deprecated/src/utils/helpers'

const CTA01 = lazyRetry(() => import(/* webpackChunkName: 'CTA' */ './CTA/CTA01/CTA01'))
const Generic01 = lazyRetry(() => import(/* webpackChunkName: 'Generic' */ './Generic'))
const EmailCTA01 = lazyRetry(
  () => import(/* webpackChunkName: 'Email' */ './CTA/EmailCTA01/EmailCTA01')
)
const ImageQuestion01 = lazyRetry(
  () => import(/* webpackChunkName: 'Image' */ './ImageQuestion/SingleChoice')
)
const NumericScale01 = lazyRetry(
  () => import(/* webpackChunkName: 'Numeric' */ './Numeric/NumericScale01/NumericScale01')
)
const SingleChoiceText = lazyRetry(
  () => import(/* webpackChunkName: 'SingleChoiceText' */ './TextQuestion/SingleChoice')
)
const MultiChoiceText = lazyRetry(
  () => import(/* webpackChunkName: 'MultiChoiceText' */ './TextQuestion/MultiChoice')
)
const TextEntryQuestion01 = lazyRetry(
  () => import(/* webpackChunkName: 'TextEntry' */ './TextQuestion/TextEntryQuestion01')
)
const MultiChoiceImage = lazyRetry(
  () => import(/* webpackChunkName: 'MultiChoiceImage' */ './ImageQuestion/MultiChoice')
)
const FlexibleQuestion = lazyRetry(
  () => import(/* webpackChunkName: 'FlexibleQuestion' */ './FlexibleQuestion')
)

export type Props = {
  scene: IScene
  isLastScene: boolean
  isPresentational: boolean
  isSwiped: boolean
  enableScroll: () => void
  onImageReady?: () => void
  goToNextPage: () => void
  goToSkipPage: (skipPage: number) => void
  locale: Locale
  isDashboardPreview?: boolean
  hasLanguageSelector?: boolean
}

type RenderTemplates = Record<
  TemplateType,
  {
    [key: string]: (props: Props) => JSX.Element
  }
>

const RENDER_TEMPLATES: RenderTemplates = {
  [TemplateType.GENERIC_01]: {
    [SceneType.SceneBuilder]: (props: Props) => <FlexibleQuestion {...props} />,
    [SceneType.Generic]: (props: Props) => <Generic01 {...props} />,
  },
  [TemplateType.QUESTION_01]: {
    [SceneType.Question]: (props: Props) => <SingleChoiceText {...props} />,
  },
  [TemplateType.QUESTION_02]: {
    [SceneType.Question]: (props: Props) => <ImageQuestion01 {...props} />,
  },
  [TemplateType.QUESTION_TEXT_ENTRY]: {
    [SceneType.Question]: (props: Props) => <TextEntryQuestion01 {...props} />,
  },
  [TemplateType.QUESTION_TEXT_MULTI]: {
    [SceneType.Question]: (props: Props) => <MultiChoiceText {...props} />,
  },
  [TemplateType.QUESTION_DATE_ENTRY]: {
    [SceneType.SceneBuilder]: (props: Props) => <FlexibleQuestion {...props} />,
  },
  [TemplateType.CTA_01]: {
    [SceneType.CTA]: (props: Props) => <CTA01 {...props} />,
  },
  [TemplateType.CTA_02]: {
    [SceneType.CTA]: (props: Props) => <EmailCTA01 {...props} />,
  },
  [TemplateType.NUMERIC_NPS]: {
    [SceneType.Numeric]: (props: Props) => <NumericScale01 {...props} />,
  },
  [TemplateType.NUMERIC_RANGE]: {
    [SceneType.Numeric]: (props: Props) => <NumericScale01 {...props} />,
  },
  [TemplateType.QUESTION_IMAGE_MULTI]: {
    [SceneType.Question]: (props: Props) => <MultiChoiceImage {...props} />,
  },
  [TemplateType.QUESTION_FLEXIBLE]: {
    [SceneType.SceneBuilder]: (props: Props) => <FlexibleQuestion {...props} />,
    [SceneType.Question]: (props: Props) => <FlexibleQuestion {...props} />,
  },
}

const Scene = (props: Props) => {
  const {
    scene: { type, template_type, id },
    isPresentational = false,
  } = props
  const testId = `${isPresentational ? 'renderingScene' : 'renderedScene'}-${id}`

  sanity(type, template_type)

  return (
    <Div100vh
      style={{ position: 'absolute', width: '100%' }}
      aria-hidden={isPresentational ? true : undefined}
      data-testid={testId}
    >
      {RENDER_TEMPLATES[template_type][type]({
        ...props,
        isPresentational,
      })}
    </Div100vh>
  )
}

const sanity = (sceneType: SceneType, templateType: TemplateType) => {
  const templateList = SceneToTemplate[sceneType] as Array<TemplateType>
  if (!templateList.includes(templateType)) {
    throw new Error(`Template type ${templateType} does not exist in scene ${sceneType}`)
  }
}

export default Scene
