import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { use100vh } from 'react-div-100vh'
import { SwipeableProps, SwipeEventData, useSwipeable } from 'react-swipeable'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { IScene } from '@top/shared_deprecated/src/types/Scene'
import useContainerWidth from '@top/shared_deprecated/src/utils/useContainerWidth'

import FullPageCircularProgress from '../FullPageCircularProgress'

import ReadyNextScene from './ReadyNextScene'

import './transitions/style.scss'

import { useDevSettings } from 'contexts/DevSettings'
import { useDistribution } from 'contexts/Distribution'
import { useTracker_DEPRECATED } from 'contexts/Tracker_DEPRECATED'
import useWheel from 'hooks/useWheel'

const SWIPE_CONFIG: SwipeableProps = {
  delta: 10, // min distance(px) before a swipe starts
  preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
  trackTouch: true, // track touch input
  trackMouse: true,
}

interface Props {
  children: (props: {
    scene: IScene
    enableScroll: () => void
    goToNextPage: () => void
    goToSkipPage: (skipPage: number) => void
    isLastScene: boolean
    isSwiped: boolean
    isPresentational: boolean
    width: number
  }) => JSX.Element
}

const TRANSITION_TIMER = 1000

const SWIPE_THRESHOLD: { [key: string]: number } = {
  UP: 0.5,
  LEFT: 0.5,
}

const SceneTransitions = (props: Props) => {
  const [canScroll, setCanScroll] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const [isSwiped, setIsSwiped] = useState(false)
  const [sceneReady, setSceneReady] = useState<{ [key: number]: boolean }>({})
  const [currentPage, setCurrentPage] = useState(0)
  const [handleWheelEvent, isWheelTriggered, wheelDirection] = useWheel()

  const {
    scenes,
    nextPage,
    isCompleted,
    setCurrentSceneUiid: setCurrentSceneUuidDist,
    setDistWidth,
  } = useDistribution()
  const { setCurrentSceneId, setCurrentSceneUiid } = useTracker_DEPRECATED()
  const { state } = useDevSettings()

  const divRef = useRef<HTMLDivElement>(null)
  const width = useContainerWidth(divRef)

  useEffect(() => {
    setDistWidth(width)
  }, [width, setDistWidth])

  const onNextScene = useCallback(
    async (pageNumber: number) => {
      // pageNumber number is the next page
      if (pageNumber !== currentPage) {
        setIsAnimating(true)
        // we are awaiting to guarantee a smooth animation
        await new Promise((resolve) => {
          setCurrentPage(nextPage)
          // add some extra ms just to be safe
          setTimeout(resolve, TRANSITION_TIMER + 200)
        })
        setIsAnimating(false)
        setCanScroll(false)
      }
    },
    [currentPage, nextPage]
  )

  useEffect(() => {
    if (!scenes) return
    const scene = scenes[currentPage]
    setCurrentSceneUiid(scene.uuid)
    setCurrentSceneUuidDist(scene.uuid)
    setCurrentSceneId(scene.id)
  }, [currentPage, scenes, setCurrentSceneId, setCurrentSceneUiid, setCurrentSceneUuidDist])

  // This function calls onInteraction under the hood
  const goToNextPage = useCallback(() => {
    if (isAnimating) return
    onNextScene(nextPage)
  }, [onNextScene, nextPage, isAnimating])

  const onNextSkipScene = useCallback(
    async (pageNumber: number) => {
      if (pageNumber !== currentPage) {
        setIsAnimating(true)
        // we are awaiting to guarantee a smooth animation
        await new Promise((resolve) => {
          setCurrentPage(pageNumber)
          // add some extra ms just to be safe
          setTimeout(resolve, TRANSITION_TIMER + 200)
        })
        setIsAnimating(false)
        setCanScroll(false)
      }
    },
    [currentPage]
  )

  const goToSkipPage = useCallback(
    (page: number) => {
      if (isAnimating) return
      onNextSkipScene(page)
    },
    [onNextSkipScene, isAnimating]
  )

  const onSwipe = (swipeData: SwipeEventData) => {
    const { velocity, dir } = swipeData
    const threshold =
      SWIPE_THRESHOLD[dir.toLowerCase()] !== undefined
        ? SWIPE_THRESHOLD[dir.toLowerCase()]
        : SWIPE_THRESHOLD['UP']

    if (velocity > threshold && dir.toUpperCase() === state.swipe) {
      setIsSwiped(true)
      setTimeout(() => {
        setIsSwiped(false)
      })
    }
    if (!canScroll || isAnimating) return
    if (velocity > threshold && dir.toUpperCase() === state.swipe) {
      goToNextPage()
    }
  }
  useEffect(() => {
    if (!isWheelTriggered || !canScroll || isAnimating) return
    if (wheelDirection === state.swipe) {
      goToNextPage()
    }
  }, [isWheelTriggered, wheelDirection, canScroll, isAnimating, goToNextPage, state])

  const getSceneToRender = () => {
    if (scenes === undefined) return
    // return first scene if not ready
    // else return next scene to render
    return !sceneReady[currentPage]
      ? scenes[currentPage]
      : !sceneReady[nextPage]
      ? scenes[nextPage]
      : undefined
  }

  const enableScroll = useCallback(() => {
    setCanScroll(true)
  }, [])

  const handleSceneReady = useCallback((num: number) => {
    setSceneReady((state) => {
      return { ...state, [num]: true }
    })
  }, [])

  const sceneToRender = getSceneToRender()
  const firstSceneReady = sceneReady[0] || currentPage !== 0

  const handlers = useSwipeable({
    onSwiped: (eventData) => onSwipe(eventData),
    ...SWIPE_CONFIG,
  })

  const height = use100vh() || '100%'
  return (
    <div ref={divRef}>
      {scenes && scenes[currentPage] && (firstSceneReady || isCompleted) ? (
        <Suspense fallback={null}>
          <div style={{ height }} {...handlers} onWheel={handleWheelEvent}>
            <TransitionGroup className="transition-container">
              <CSSTransition
                unmountOnExit
                key={currentPage}
                timeout={1000}
                classNames={state.animation}
              >
                {props.children({
                  scene: scenes[currentPage],
                  enableScroll,
                  goToNextPage,
                  goToSkipPage,
                  isLastScene: currentPage === scenes.length - 1,
                  isSwiped: isSwiped || (isWheelTriggered && wheelDirection === state.swipe),
                  isPresentational: isAnimating,
                  width,
                })}
              </CSSTransition>
            </TransitionGroup>
          </div>
        </Suspense>
      ) : (
        <FullPageCircularProgress size={60} />
      )}

      <Suspense fallback={null}>
        {sceneToRender && <ReadyNextScene onSceneReady={handleSceneReady} scene={sceneToRender} />}
      </Suspense>
    </div>
  )
}

export default SceneTransitions
