import { Box } from '@mui/material'

import COLORS from '@top/shared/src/style/colors'
import {
  type Alignment,
  type ButtonStyle,
  ButtonWidth,
  type NextButtonType,
} from '@top/shared/src/style/types'
import CheckCircleOutline from '@top/ui/src/Icons/CheckCircleOutline'
import { AnimatedTransitionButton } from '@top/ui/src/SceneWrapper/NextButton/AnimatedTransitionButton'
import { ContainedButton } from '@top/ui/src/SceneWrapper/NextButton/ContainedButton'
import { TransitionButton } from '@top/ui/src/SceneWrapper/NextButton/TransitionButton'
import { type NextButtonProps } from '@top/ui/src/SceneWrapper/NextButton/types'

export const defaultNextButtonStyle = {
  alignment: 'center' satisfies Alignment,
  backgroundColor: COLORS.BLACK,
  color: COLORS.SUBTITLE_TEXT,
  fontFamily: 'Roboto',
  fontSize: 12,
  italic: false,
  bold: false,
  underline: false,
  buttonWidth: ButtonWidth.Long,
} satisfies ButtonStyle

export const defaultNextButtonType = 'BUTTON_CONTAINED' satisfies NextButtonType

const NextButton = (props: NextButtonProps) => {
  const {
    type,
    style,
    text,
    onClick,
    isHidden = false,
    disabled = false,
    onChange,
    isBuilder,
    widgetWidth,
    isSubmitted = false,
    parentTestId,
    onFocus,
    onBlur,
    focused = false,
    showError = false,
  } = props

  const { alignment } = style

  const testId = parentTestId ? `${parentTestId}-next-button` : undefined

  const buttons = {
    BUTTON_CONTAINED: (
      <ContainedButton
        style={style}
        text={text}
        disabled={disabled}
        parentTestId={testId}
        onChange={onChange}
        isBuilder={isBuilder}
        onClick={onClick}
        widgetWidth={widgetWidth}
        onFocus={onFocus}
        onBlur={onBlur}
        focused={focused}
        showError={showError}
      />
    ),
    BUTTON_ARROW: (
      <TransitionButton
        style={style}
        text={text}
        disabled={disabled}
        parentTestId={testId}
        isBuilder={isBuilder}
        onChange={onChange}
        onClick={onClick}
        widgetWidth={widgetWidth}
        onFocus={onFocus}
        onBlur={onBlur}
        focused={focused}
        showError={showError}
      />
    ),
    BUTTON_ANIMATED: <AnimatedTransitionButton parentTestId={testId} onClick={onClick} />,
  } satisfies {
    [key in NextButtonType]: React.ReactNode
  }

  if (isSubmitted) {
    return (
      <CheckCircleOutline
        fontSize="large"
        style={{
          color: COLORS.BLACK,
          height: '2.5rem',
          marginTop: '10%',
        }}
      />
    )
  }

  return (
    <Box
      data-testid={testId}
      sx={{
        display: 'flex',
        transition: '0.2s opacity ease-in-out',
        padding: '0 8px',
        height: 'content-max',
        visibility: isHidden ? 'hidden' : undefined,
        width: '100%',
        boxSizing: 'border-box',
        justifyContent: alignment,
      }}
    >
      {buttons[type]}
    </Box>
  )
}

export default NextButton
